<template>
    <div class="pt-3 justify-center d-flex flex-column">
        <v-menu offset-y>
            <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                            height="36px"
                            small
                            v-on="{...tooltip, ...menu}"
                        >
                            <v-icon small dark v-on>ti ti-export</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('message.export') }}</span>
                </v-tooltip>
            </template>
            <v-list dense>
                <v-list-item @click="exportTable('pdf')">
                    <v-list-item-title>{{ $t('message.exportAsPdf') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="exportTable('excel')">
                    <v-list-item-title>{{ $t('message.exportAsExcel') }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    import {mapFields} from "vuex-map-fields";
    import {api} from "Api";

    export default {
        name: "ExportTableJson",
        props: ['exportName','exportData','exportFields','exportConditions','exportSource'],
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            })
        },
        methods: {
            async exportTable(reportType) {
                let date = new Date()
                let day = date.getDate();
                let month = date.getMonth()+1;
                let year = date.getFullYear();
                let fullDate = year + "-" + month + "-" + day

                let reportFileExtension = '.pdf'
                let reportFileType = 'application/pdf'
                if(reportType == 'excel'){
                    reportFileExtension = '.xlsx'
                    reportFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
                if (this.exportData.length > 0 || typeof this.exportData === 'object') {
                    try {
                        this.$toast(this.$t('message.successes.exportedListReadyMoment'), {
                            classes: ['text--white'],
                            color: 'info',
                            timeout: 0,
                            icon: 'check',
                            iconColor: 'white',
                            x: 'center',
                            y: 'top'
                        })

                        let data = []
                        let apiEndpoint = '';
                        let reportFileName = ''

                        switch(this.exportSource){
                            case 'accounts-receivable-report':
                                apiEndpoint = '/reports/buyer-aging/export'
                                reportFileName = 'AR_Report_' + fullDate
                                data = this.exportData
                                break;
                            case 'appic-xero-comparison':
                                apiEndpoint = '/reports/appic-xero-comparison/export'
                                reportFileName = 'APPiC_Xero_Comparison_' + fullDate
                                data = this.exportData
                                break;
                            case 'appic-xero-cost-of-sales-comparison':
                                apiEndpoint = '/reports/appic-xero-cost-of-sales-comparison/export'
                                reportFileName = 'APPiC_Xero_Cost_Of_Sales_Comparison_' + fullDate
                                data = this.exportData
                                break;
                            case 'buyer-locations':
                                apiEndpoint = '/customers/locations/export'
                                reportFileName = 'Locations_List_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        Profile: ed.profile
                                    })
                                })
                                break;
                            case 'customers':
                                apiEndpoint = '/customers/export'
                                reportFileName = 'Customers_List_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        Company: ed.Company,
                                        Country: ed.Country,
                                        Customer: ed.Customer,
                                        Marketsegment: ed.Marketsegment,
                                        Salescontact: ed.Salescontact
                                    })
                                })
                                break;
                            case 'debitnotes':
                                apiEndpoint = '/debitnotes/export'
                                reportFileName = 'Debit_Notes_List_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        DebitNote: ed.DebitNote
                                    })
                                })
                                break;
                            case 'document-status-report':
                                apiEndpoint = '/reports/document-status/export'
                                reportFileName = 'Document_Status_List_' + fullDate
                                data = this.exportData
                                break;
                            case 'ets-due':
                                apiEndpoint = '/reports/ets-due/export'
                                reportFileName = 'ETS_Due_' + fullDate
                                data = this.exportData
                                break
                            case 'grossmargins':
                                apiEndpoint = '/grossmargins/export'
                                reportFileName = 'Gross_Margins_List_' + fullDate
                                data = this.exportData
                                break;
                            case 'incoming-stock':
                                apiEndpoint = '/reports/stocks/incoming/export'
                                reportFileName = 'Incoming_Stock_List_' + fullDate
                                data = this.exportData
                                break;
                            case 'invoices':
                                apiEndpoint = '/invoices/export'
                                reportFileName = 'Invoices_List_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        Invoice: ed.Invoice
                                    })
                                })
                                break;
                            case 'localsales':
                                apiEndpoint = '/localsales/export'
                                reportFileName = 'Warehouse_Invoices_List_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        LocalSalesInvoice: ed.LocalSalesInvoice
                                    })
                                })
                                break;
                            case 'marketing-report':
                                apiEndpoint = '/utilities/export/marketing-report/' + reportType
                                reportFileName = 'Marketing_Report_' + fullDate
                                data = this.exportData
                                break;
                            case 'ncrs':
                                apiEndpoint = '/ncrs/export'
                                reportFileName = 'Contract_Requests_List_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        Ncr: ed.Ncr,
                                        Contract: ed.Contract
                                    })
                                })
                                break
                            case 'ots-access-log':
                                apiEndpoint = '/reports/buyer-ots-access/export'
                                reportFileName = 'Buyer_OTS_Access_Log_' + fullDate
                                data = this.exportData
                                break
                            case 'purchaseorders':
                                apiEndpoint = '/purchaseorders/export'
                                reportFileName = 'Purchase_Orders_List_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        Purchaseorder: ed.Purchaseorder,
                                        Supplierpayment: ed.Supplierpayment
                                    })
                                })
                                break
                            case 'salesorders':
                                apiEndpoint = '/localsalesorders/export'
                                reportFileName = 'Sales_Orders_List_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        SalesOrder: ed.SalesOrder
                                    })
                                })
                                break;
                            case 'shipments':
                                apiEndpoint = '/shipments/export'
                                reportFileName = 'Shipments_List_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        Agent: ed.Agent,
                                        Contract: ed.Contract,
                                        Destinationport: ed.Destinationport,
                                        Invoice: ed.Invoice,
                                        Originport: ed.Originport,
                                        Pldo: ed.Pldo,
                                        Shipment: ed.Shipment,
                                        Shipmentstatus: ed.Shipmentstatus,
                                        Status: ed.Status
                                    })
                                })
                                break;
                            case 'stockorders':
                                apiEndpoint = '/stockorders/export'
                                reportFileName = 'Stock_PO_List_' + fullDate
                                data = this.exportData
                                break;
                            case 'stocks':
                                apiEndpoint = '/stocks/export'
                                reportFileName = 'Master_Stock_List_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        Stock: ed.Stock,
                                        Contract: ed.Contract,
                                        Product: ed.Product,
                                        Species: ed.Species,
                                        Grade: ed.Grade
                                    })
                                })
                                break;
                            case 'suppliers':
                                apiEndpoint = '/suppliers/export'
                                reportFileName = 'Suppliers_List_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        Agent: ed.Agent,
                                        Company: ed.Company,
                                        Country: ed.Country,
                                        Product1: ed.Product1,
                                        Product2: ed.Product2,
                                        Product3: ed.Product3,
                                        Species: ed.Species,
                                        Supplier: ed.Supplier
                                    })
                                })
                                break;
                            case 'supplier-product-search':
                                apiEndpoint = '/products/export'
                                reportFileName = 'Suppliers_Products_List_' + fullDate
                                data = this.exportData
                                break;
                            case 'supplier-product-table':
                                apiEndpoint = '/products/export'
                                reportFileName = 'Suppliers_Products_List_' + fullDate
                                data = this.exportData
                                break;
                            case 'stocks-weekly-detail':
                                apiEndpoint = '/reports/stocks/weekly-stock-movement/export'
                                data = this.exportData
                                break;
                            case 'weekly-sales':
                                apiEndpoint = '/reports/stocks/weekly-sales/export'
                                reportFileName = 'Weekly_Sales_' + fullDate
                                data = this.exportData
                                break;
                            case 'stocks-sales-weekly-detail':
                                apiEndpoint = '/reports/stocks/weekly-sales-detail/export'
                                data = this.exportData
                                break;
                            case 'wprs':
                                apiEndpoint = '/wprs/export'
                                reportFileName = 'Warehouse_Purchase_Requests_List_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        Wpr: ed.Wpr
                                    })
                                })
                                break;
                            case 'wh-credit-limit-summary':
                                apiEndpoint = '/reports/warehouse-sales-credit/export'
                                reportFileName = 'WH_Credit_Limit_Summary_' + fullDate
                                data = {
                                    data: this.exportData.data,
                                    salesYears: this.exportData.salesYears
                                }
                                break;
                        }

                        let exportedFile = await api.post(apiEndpoint, {
                            conditions: this.exportConditions,
                            data: data,
                            fields: this.exportFields,
                            type: reportType
                        }, {
                            responseType: 'blob'
                        })

                        const url = window.URL.createObjectURL(new Blob([exportedFile.data], {type: reportFileType}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.target = '_blank'
                        link.setAttribute('download', reportFileName + reportFileExtension);
                        link.click();
                        link.remove();
                        window.URL.revokeObjectURL(url);
                        //close notification
                        let cmp = this.$toast.getCmp()
                        cmp.close()
                    } catch (error) {
                        this.$toast.error(this.$t('message.errors.couldNotExportList') + ": " + error, {
                            timeout: 7000,
                            x: 'center',
                            y: 'top'
                        })
                    }
                } else {
                    alert(this.$t('message.noDataToExport'))
                }
            }
        }
    }
</script>

<style>
.v-snack__content {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}
</style>